import { IncomingMessage } from 'http'
import axios from 'axios'
import { ParsedUrlQuery } from 'querystring'
import {
	Language,
	PageByUrlQuery,
	PageByUrlQueryVariables,
	PageCommonQuery,
	PageCommonQueryVariables,
	ArticleBySlugQueryVariables,
	EventBySlugQueryVariables,
	ArticleBySlugQuery,
	EventBySlugQuery,
	SeoQuery,
	SeoQueryVariables,
	PagePreviewQuery,
	PagePreviewQueryVariables,
	ImageByUrlQuery,
	ImageByUrlQueryVariables,
	Sdk,
	DocumentByUrlQuery,
	DocumentByUrlQueryVariables,
	SearchIndexQuery,
	SearchIndexQueryVariables,
	EventsIndexQuery,
	EventsIndexQueryVariables,
} from 'generated/sdk'

export type RawParams = { [key: string]: string | number }
export type StringParams = { [key: string]: string }

const NUMBER_PARAMS = ['page', 'perPage', 'before', 'limit', 'offset']

export const stringifyParams = (params: RawParams) => {
	const encodedParams = encodeParams(params)
	return Object.keys(encodedParams)
		.map(key => `${key}=${encodedParams[key]}`)
		.join('&')
}

export const parseParams = (params: StringParams) => {
	const decodedParams = decodeParams(params)
	const parsedParams = {} as RawParams
	Object.keys(decodedParams).forEach(key => {
		parsedParams[key] = NUMBER_PARAMS.includes(key) ? parseInt(decodedParams[key], 10) : decodedParams[key]
	})
	return parsedParams
}

const encodeParams = (params: RawParams) => {
	const encodedParams = {} as StringParams
	Object.keys(params).forEach(key => {
		encodedParams[encodeURIComponent(key)] = encodeURIComponent(params[key])
	})
	return encodedParams
}

const decodeParams = (params: StringParams) => {
	const parsedParams = {} as StringParams
	Object.keys(params).forEach(key => {
		parsedParams[decodeURIComponent(key)] = decodeURIComponent(params[key])
	})
	return parsedParams
}

export const removeTrailingSlash = (url: string) => {
	const [path, query] = url.split('?')
	const trimmedPath = path.length > 1 && path.endsWith('/') ? path.slice(0, -1) : path
	return `${trimmedPath}${query ? `?${query}` : ''}`
}

export const getLocationOrigin = (req?: IncomingMessage) => {
	if (req) {
		const protocol = req.headers['x-forwarded-proto'] ? `${req.headers['x-forwarded-proto'] as string}:` : 'http:'
		return `${protocol}//${req.headers.host || ''}`
	}
	return window.location.origin
}

export const getApiUrl = (req?: IncomingMessage) => {
	const origin = req ? (process.env.VERCEL_URL ? `https://${process.env.VERCEL_URL}` : getLocationOrigin(req)) : ''
	return `${origin}/api/graphql`
}

export const getUserAgent = (req?: IncomingMessage) => {
	return req ? req.headers['user-agent'] : navigator.userAgent
}

export const getLanguage = (query: ParsedUrlQuery) => {
	const language = query.slug ? query.slug[0] : ''
	return Object.values(Language).includes(language as Language) ? language : Language.Pt
}

export const getYouTubeId = (url: string) => {
	const localUrl = url.split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/)
	return localUrl[2] !== undefined ? localUrl[2].split(/[^0-9a-z_-]/i)[0] : localUrl[0]
}

export const getUrlPathFromQuery = (query: string[]) => {
	return `/${query.join('/')}/`
}

export type QueryName = keyof Sdk

const fetchGraphQLData = async <Q, V>(queryName: QueryName, variables: V, api: string) => {
	try {
		const response = await axios.get<Q>(api, { params: { queryName, ...variables } })
		return response.data
	} catch (error) {
		throw error
	}
}

export const pageCommonFetcher = async ([queryName, language]: [QueryName, Language], api = getApiUrl()) => {
	const variables = { language }
	return fetchGraphQLData<PageCommonQuery, PageCommonQueryVariables>(queryName, variables, api)
}

export const pageByUrlFetcher = async (
	[queryName, urlPath, startDate, searchQuery, page = 1, before = null]: [
		QueryName,
		string,
		string,
		string,
		number,
		number | null,
	],
	api = getApiUrl()
) => {
	const variables = { urlPath, startDate, searchQuery, page, before }
	return fetchGraphQLData<PageByUrlQuery, PageByUrlQueryVariables>(queryName, variables, api)
}

export const articleBySlugFetcher = async (slug: string, api = getApiUrl()) => {
	const variables = { slug }
	return fetchGraphQLData<ArticleBySlugQuery, ArticleBySlugQueryVariables>('ArticleBySlug', variables, api)
}

export const eventBySlugFetcher = async (slug: string, api = getApiUrl()) => {
	const variables = { slug }
	return fetchGraphQLData<EventBySlugQuery, EventBySlugQueryVariables>('EventBySlug', variables, api)
}

export const seoFetcher = async ([queryName, language]: [QueryName, Language], api: string = getApiUrl()) => {
	const variables = { language }
	return fetchGraphQLData<SeoQuery, SeoQueryVariables>(queryName, variables, api)
}

export const pageByContentTypeFetcher = async (
	[queryName, token, contentType]: [QueryName, string | undefined, string | undefined],
	api: string = getApiUrl()
) => {
	const variables = { token, contentType }
	return fetchGraphQLData<PagePreviewQuery, PagePreviewQueryVariables>(queryName, variables, api)
}

export const imageByUrlFetcher = async (urlPath: string, api = getApiUrl()) => {
	const variables = { urlPath }
	return fetchGraphQLData<ImageByUrlQuery, ImageByUrlQueryVariables>('ImageByUrl', variables, api)
}

export const documentByUrlFetcher = async (urlPath: string, api = getApiUrl()) => {
	const variables = { urlPath }
	return fetchGraphQLData<DocumentByUrlQuery, DocumentByUrlQueryVariables>('DocumentByUrl', variables, api)
}

export const searchIndexFetcher = async (slug: string, api = getApiUrl()) => {
	const variables = { slug }
	return fetchGraphQLData<SearchIndexQuery, SearchIndexQueryVariables>('SearchIndex', variables, api)
}

export const eventsIndexFetcher = async (slug: string, api = getApiUrl()) => {
	const variables = { slug }
	return fetchGraphQLData<EventsIndexQuery, EventsIndexQueryVariables>('EventsIndex', variables, api)
}
